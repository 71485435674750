<template >
    <div  id="dept-box">
      <template v-if="departmentData">
        <template >        
          <div  v-if='departmentData.PstName!=""||departmentData.PstName==null' 
          class="department"  :id="'ID_'+ departmentData.PstID" :class="[type,{'worker':managerNameView}]">  
            <div class="level_indicator" :style="{'backgroundColor':'#000000'}"></div>
            <template>
              <div  class="name1"  v-html="departmentData.PstName"></div>
              <div class="name_manager" v-if="managerNameView">{{departmentData.Workers}}</div>
            </template>
          </div>
          <div v-else 
           class=" line" :id="'ID_'+ departmentData.PstID" :class="[type,{'worker':managerNameView}]">  
          <div class="linev" :class="[{'worker':managerNameView}]"></div>
          </div>          
        </template>   
      </template>
    </div>
</template>
<script>
export default {
    name: 'dept-box',
    props: {
        departmentData: {
            type: Object,
            default: null
        },
         managerNameView:false,
        type: { type: String, default: ''},
  },
  data: function() {
    return {}
    }
}
</script>

<style scoped>
.worker{
  height: 120px !important;
}
.down-icon1 {
  width: 20px;
  height: auto;
  margin: 3px 0px 0px 0px;
}
.profile {
  width: 55px;
  max-height: 55px;
  display: block;
  margin: auto;
  border-radius: 30px;
}
.active_department {
  background-color: yellow !important;
  color: black !important;
}
.arrow {
  font-size: 30px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin: -8px;
}
.down {
  cursor: zoom-in;
}
.up {
  cursor: zoom-out;
}
.view_button {
  font-size: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  color: black;
}
.linev{
   width: 120px;
  height: 60px;
   border-left: 2px solid rgb(180, 180, 180);
    border-right: 0px solid rgb(180, 180, 180);
  position: absolute;
  margin-left: -3px;
  top: 0;
    left: 52%;
}
.line{
 

    width: 120px;
  height: 60px;
  margin: 20px 0px 5px 0px;
  text-align: center;
  font-size: 11px;
  vertical-align: middle;
  display: flex;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 2px;
  position: relative;
}
.department {
  width: 120px;
  height: 60px;
  border: 1px solid rgb(180, 180, 180);
  margin: 20px 0px 5px 0px;
  text-align: center;
  font-size: 11px;
  vertical-align: middle;
  display: flex;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  padding: 2px 2px;
  position: relative;
  /*box-shadow: 3px 3px 3px lightgrey;*/
}
.manager_photo {
  width: 180px;
  height: 60px;
  margin-top: 20px;
}
.invisible {
  visibility: hidden;
}
.level_indicator {
  position: absolute;
  height: 3px;
  width: 100px;
  right: 10px;
  top: 3px;
  border-radius: 5px;
}
.textdiv {
  width: 114px;
  height: 30px;
  position: relative;
}
.column {
  margin-top: 1px;
  margin-bottom: 0px;
}
.staff,
.staff_column {
  margin: 2px 80px 2px 80px;
}
.staff_child {
  margin: 2px 80px 2px 100px;
}
.name,
.name1,
.name2 {
  text-align: center;
  overflow-wrap: break-word;
  min-width: 1%;
  width: 114px;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 8px;

}
.name1 {
  top: 10px;

 font-weight: bold;
}
.name2 {
  top: 14px;
}
.name_manager {
  text-align: center;
  overflow-wrap: break-word;

  width: 100px;
  color: grey;
  font-size: 9px;
}
.hidden_dept {
  position: absolute;
  bottom: 10px;
  right: 1px;
  width: 14px;
  background-color: white;
  color: grey;
  font-size: 12px;
  padding: 0px 2px;
  border-radius: 4px;
}
.ppl_count {
  position: absolute;
  bottom: 1px;
  left: 1px;
  background-color: white;
  color: grey;
  font-size: 12px;
  padding: 0px 2px;
  border-radius: 4px;
}
.hidden_parents,
.hidden_parents1 {
  position: absolute;
  top: -24px;
  left: 78px;
  font-size: 24px;
  color: grey;
}
.hidden_parents1 {
  left: 50px;
}
</style>
