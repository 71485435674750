import Vue from 'vue'

const menu="HumanResource/OrganizationLevel/";

export default {

    pagination(dtr){  return Vue.prototype.$http.post(menu+"NoList",dtr);},
    listLevelOrg(parent){  return Vue.prototype.$http.post(menu+"org",parent);},
    listLevel(parent){  return Vue.prototype.$http.post(menu+"list",parent);},
    save(obj,usr){
       return Vue.prototype.$http.post(menu+"save",obj,{params:{usrID:usr}});
    }
}