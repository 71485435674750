<template>
  <div id="draw-lines" class="">
    <svg id="svg" class="graph">
      <path
        v-if="lines.length > 0"
        v-for="line in lines"
        :d="line.d"
        style="stroke:#b6b4b4;stroke-width:2"
        fill="transparent"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  name: "draw-lines",
  props: { refreshLine: Boolean, chart: Object },
  data: function() {
    return {
      lines: [],
    };
  },
  methods: {
    refreshLines() {
      this.lines=[];
      this.addLine();
    },
    addLine() {
      this.lines = this.updateLines(this.chart, []);
    },
    updateLines(position, lines) {
      var svg = document.getElementById("svg");
      var xparent = document.getElementById("org");
      svg.style.width = xparent.offsetWidth + "px";
      svg.style.height = xparent.offsetHeight + "px";
      var line;
      position.children.forEach((child) => {
        line = this.getLine(child);
        
        if (line) {          
          lines.push(line);
          this.updateLines(child, lines);                  
        }
      });
      return lines;
    },
    getLine(position) {
      var positionT = position;
      var pos = this.getPosOfElement(positionT);
      if (!pos.parent) return null;
      var d;
      var x = document.getElementById("org");
      var scale = 1 / (x.getBoundingClientRect().width / x.offsetWidth);
      if (position.PstIsAsistent) {
        d = "M" + Math.round(pos.parent.x + pos.parent.width / 2) * scale +
            " " + Math.round(pos.parent.y + pos.parent.height) * scale +
            " v" + Math.round(pos.element.y - pos.parent.y - pos.parent.height / 2) * scale +
            " H" + Math.round(pos.element.x + pos.parent.width) * scale;
      } else {
        d = "M" + Math.round(pos.parent.x + pos.parent.width / 2) * scale + " " +
          Math.round(pos.parent.y + pos.parent.height) * scale +
          " V" + Math.round(pos.element.y * scale - 10) +
          " H" + Math.round(pos.element.x + pos.parent.width / 2) * scale +
          " V" + Math.round(pos.element.y) * scale;
      }
      return { d: d };
    },
    getPosOfElement(dept) {
      let x = dept;
      var parentElement = document.getElementById("ID_" + dept.parent.PstID);
      var childElement = document.getElementById("ID_" + dept.PstID);
      if (!parentElement || !childElement) return { parent: null };
      var pos = {
        parent: dept.parent ? document.getElementById("ID_" + dept.parent.PstID).getBoundingClientRect(): null,
        element: document.getElementById("ID_" + dept.PstID).getBoundingClientRect(),
      };
      var chartpos = document.getElementById("org").getBoundingClientRect();
      if (pos.parent) {
        pos.parent.x = pos.parent.left - chartpos.left;
        pos.parent.y = pos.parent.top - chartpos.top;
      }
      pos.element.x = pos.element.left - chartpos.left;
      pos.element.y = pos.element.top - chartpos.top;
      return pos;
    },
  },

  watch: {
    refreshLine: function(sdd, sds) {
      if (this.chart) this.refreshLines();
    },
  },
  mounted() {
    this.$nextTick().then(()=>{
      this.$emit('loadLines');
    })
   
    this.refreshLines();
    
  },
};
</script>
<style scoped>
.graph {
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}
</style>
