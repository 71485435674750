<template>    
    <panZoom >
        <div id="org" >
            <show-dept v-if=chartV :parent="chartV" :TypeNivel="TypeNivel" :level="0" @up="up()" :managerNameView="managerNameView" :report="report"></show-dept>
            <draw-lines @loadLines=loadLines() :refreshLine="refreshLine" v-if="chartV" :chart="chartV"></draw-lines>
        </div>
    </panZoom>
</template>

<script>
import ShowDept from "./ShowDep";
import DrawLines from "./DrawLines";

import OrgService from './../../services/HumanResource/OrganizationLevelService'


export default {
    name: "Org",    
    components: { ShowDept, DrawLines },    
   
    data() {
        return {
            showOrgChart: true,
            loadBox: false,
            refreshLine: false,
            orgArray: [],
            data: [],
            chartV: null,
            chart: null,
            people: [],
            nodes: [
                { id: 1, name: 'CEO', title: 'Chief Executive Officer' },
                { id: 2, pid: 1, name: 'Manager 1', title: 'Manager' },
                { id: 3, pid: 1, name: 'Manager 2', title: 'Manager' },
                { id: 4, pid: 2, name: 'Team Lead 1', title: 'Team Lead' },
                { id: 5, pid: 2, name: 'Team Lead 2', title: 'Team Lead' },
                { id: 6, pid: 3, name: 'Team Lead 3', title: 'Team Lead' },
                { id: 7, pid: 3, name: 'Team Lead 4', title: 'Team Lead' }
            ],
            config: {
                nodeWidth: 150,
                nodeHeight: 50,
                nodeSpacing: 20,
                direction: 'TB'
            }
        };
    },
    props: {
        parent: { type: Object, default: {} },
        level: { type: Number, default: 0 },
        managerNameView: Boolean,
        id: 0,
        report: false,
        TypeWorker: { type: Number, default: 0 },
        TypePosition: { type: Number, default: 0 },
        TypeNivel: { type: Number, default: 0 },
    },
    methods: {
        onInit: function (panzoomInstance, id) {
            
          
            if(this.report){
                panzoomInstance.setMaxZoom(7);
                panzoomInstance.setMinZoom(2);
                panzoomInstance.on('beforeWheel', function (e) {
                    // var shouldIgnore =  ! e . altKey ;
                    //  return shouldIgnore;
                });
                panzoomInstance.zoomAbs(
                    0, // initial x position
                    0, // initial y position
                    2 // initial zoom
                );
            }
            else{
                panzoomInstance.setMaxZoom(1.5);
                panzoomInstance.setMinZoom(0.8);
                //panzoomInstance.centeOn(true);
                //panzoomInstance.bounds=true;
                //panzoomInstance.boundsPadding=0.1;

                panzoomInstance.on('beforeWheel', function (e) {
                    // var shouldIgnore =  ! e . altKey ;
                    //  return shouldIgnore;
                });
                panzoomInstance.zoomAbs(
                    0, // initial x position
                    0, // initial y position
                    0.8 // initial zoom
                );
            }
        },
        processData(dept, orgArray) {
            dept.OrgIsAsistent = dept.OrgIsAsistent == 1 ? true : false;
            dept.showChildren = true;
            orgArray.push(dept);
            dept.children.forEach((c) => {
                c.parent = dept;
                
                this.processData(c, orgArray);
            });
            if (dept.children.length > 0) return { dept: dept, orgArray: orgArray };
            else return { dept: dept, orgArray: orgArray };
        },
        loadLines(v) {

            this.$nextTick().then(() => {
              
            });
        },
        up() {
            
            this.refreshLine = !this.refreshLine;
            this.$nextTick(function () {
                this.$emit("upOrg");  this.$emit('printImg')
            });
        },
        load(){
            let obj = {
                PstID: null,
                PstID: this.parent.PstID,
                TypeWorker: 1,
                PstIsPermanent:this.TypePosition,
                Level: 50,
                IsReportProfile: this.report,
                PstParentID: this.parent.PstParentID
            }

        
          
        OrgService.listLevelOrg(obj).then((r) => {

            
            this.chart = r.data;
            this.chartV = this.processData(this.chart, []).dept;
        })
        
        }
    },
    created() {
        this.load();
    },
    watch: {
        TypeWorker() {},
        TypePosition() {},
        id: function (val, val2) {},
        level: function (val, val2) {},
    },
    mounted() {
         

    },

};
</script>

<style scoped>
#org {
    position: relative;
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    min-width: 300px;
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    background-color: white;
    cursor: grab;
    height: 100% !important;
}

.chart-container {
    margin: auto;
    display: inline-block;
}

.arrow {
    font-size: 30px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin: -8px;
}

.down {
    cursor: zoom-in;
}

.up {
    cursor: zoom-out;
}

.panzom {
    height: 100% !important;
    width: 100%;
}
</style>
