<template >
  <div class="  ">
    <table>
      <div v-if="!staffChildren.length">
        <tr>
          <td>            
            <dept-box :managerNameView=managerNameView  :department-data="parent" :level="level" type='normal'></dept-box>
            <template v-if="(!parent.parent || parent.parent.showChildren) && !parent.showChildren">
              <table v-if="!(parent.OrgIsAsistent )">
                <tr v-for="child in parent.GenPositionChild" >
                  <td>      
                    {{ 4 }}              
                    <dept-box :managerNameView=managerNameView  :department-data="child" :level="level+1" type='column'></dept-box>
                  </td>
                </tr>
              </table>
            </template>
          </td>
        </tr>
      </div>
      <div v-else>
        <tr rowspan="2">           
            <td class="dept">
              <dept-box  :department-data="parent" :level="level" type='normal'></dept-box>
              <template v-if=" (!parent.parent || parent.parent.showChildren) && !parent.showChildren">
                <table v-if="!(parent.OrgIsAsistent)">
                  <tr v-for="child in parent.GenPositionChild" >
                    <td>
                      {{ 3 }}
                      <dept-box :managerNameView=managerNameView :department-data="child" :level="level+1" type='column'></dept-box>
                    </td>
                  </tr>
                </table>
              </template>
            </td>
            <td></td>
        </tr>
        <tr v-if="parent.showChildren">
          <td>
            <div class="staff-left">
              <table>
                <tr v-for="staff in staffChildren" >
                  <td>
                    {{ 1 }}
                    <show-dept :managerNameView=managerNameView  :TypeNivel="TypeNivel"
                     :parent="staff.left" :level="level+1"></show-dept>
                  </td>
                </tr>
              </table>
            </div>
          </td>
          <td class="dept"></td>
          <td>
            <div class="staff-right">
              <table>
                <tr v-for="staff in staffChildren" >
                  <td>
               
                    <show-dept :managerNameView=managerNameView :TypeNivel="TypeNivel" v-if="staff.right" :parent="staff.right" :level="level+1"></show-dept>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>


      </div>
    </table>

    <!-- RECURSIVO JERARQUIA HIJOS -->
    <table>
      <tr v-if="parent.showChildren">
         <!-- HIJOS DE JERARQUIA NO ASISTENTES -->    
       
        <td v-for="(child, index) in hierarchyChildren" :key="index" >
          <div v-if="TypeNivel == 1"> 
            <div v-if="child.PstName != ''" >
              <show-dept  :managerNameView=managerNameView v-if="level < nivel" :TypeNivel="TypeNivel" :parent="child" :level="level+1"></show-dept>
            </div>
            <div v-else>              
              <show-dept  :managerNameView=managerNameView v-if="level < nivel" :TypeNivel="TypeNivel" :parent="child" :level="level+2"></show-dept>
            </div>
          </div>  
          <div v-else>
            <show-dept  :managerNameView=managerNameView :TypeNivel="TypeNivel" :parent="child" :level="level+1"></show-dept>
          </div>       
        </td>
      </tr>
    </table>  

  </div>
</template>

<script>
import DeptBox from './OrgBox'

export default {
  name: 'ShowDept',
  components: { DeptBox },
  props: {
  managerNameView:Boolean,
    parent: {
      type: Object,
      required: true,
      default: null
    },
    level: {
      type: Number,
      required: true,
      default: 0
    },
    TypeNivel: {
      type: Number,
      required: true,
      default: 0
    },

  },

  data: function() {
    return {
      showChildren: false.valueOf,
      nivel: 1
    }
  },
  watch:{
    parent(){
       this.$nextTick(function () {
     this.$emit("up");
     
  })
    },
    managerNameView(){
         this.$nextTick(function () {
     this.$emit("up");
  })
    }
  },
  mounted(){
    
    this.$nextTick(function () {
     this.$emit("up");
  })
  },
  
  computed: {
    //HIJOS QUE NO SON ASISTENTES
    
    hierarchyChildren() {    
      console.log(this.level);
      let children = this.parent.children.filter(e => e.OrgIsAsistent==0);      
      
      if(this.level == 2)
      {
        this.nivel = 3;     
        
      }     
      // console.log(this.level, this.parent.children.filter(e => e.OrgIsAsistent==0));  
      return this.parent.children.filter(e => e.OrgIsAsistent==0)
    },
    //ASISTENTES
    staffChildren() {
      
      var staff = this.parent.children.filter(e => e.OrgIsAsistent==1)
      var result = []
      staff.forEach((e, i) => {
        if (i % 2 === 0)  result.push({ left: e, right: null });
        else              result[result.length - 1].right = e
      })
      
      return result
    },
  }
}
</script>
<style scoped>
table {
  table-layout: fixed;
  border: 0px solid green;
  margin: auto;
}
td {
  border: 0px dashed rgba(100, 100, 100, 0.3);
  width: 30%;
}
td.dept {
  width: 60px;
}
div.staff-left {
  float: right;
}
div.staff-right {
  float: left;
}
.container {
  display: inline-block;
  position: relative;
}
table.staff {
  margin: auto;
}
.parent {
  display: inline-block;
  border: 1px solid grey;
}
.child {
  display: inline-block;
}
.dept {
  border: 0px solid red;
}
table {
  table-layout: fixed;
}
tr {
}
td {
  text-align: center;
  vertical-align: top;
  width: 150px;
  padding: 0px 3px;
}
</style>
